import React from 'react';
import {Grid, Link, Typography, Card, CardMedia, CardContent, CardActionArea } from '@material-ui/core';
import aboutImage from '../components/about-image.jpg';
import { makeStyles } from '@material-ui/core/styles';

const UseStyles = makeStyles({
    root: {
        width: '90%', 
        maxWidth: 900,
        margin: '50px auto',
    },
    media: {
        height: 250,
        paddingTop: '500px'
    },
    card: {
        padding: 35,
    },
    about: {
        padding: 25,  
        margin: '50px auto'
    },
    paragraph: {
        
        paddingRight: '50px',
        paddingLeft: '50px',
    },
});


export default function about() {
    const classes = UseStyles();

    return(
        <Grid className={classes.about}>
            <Grid align='center'>
                <h1 style={{ color: 'white' }}>About Us</h1>
            </Grid>
            <Card className={classes.root}>
                <CardActionArea>
                    <CardMedia
                        className={classes.media}
                        alt="Personal Biography"
                        image={aboutImage}
                        title="Personal Biography"
                    />
                    <Grid container spacing={1}>
                        <Grid item sm={6} xs={12}>
                            <CardContent className={classes.card}>
                                <Typography gutterBottom variant="h5" component="h2">
                                    The Creator
                                </Typography>
                                <Typography variant="body1" color="textSecondary" component="p">
                                    Hello,<br/>
                                My name is Daniel Crouse, and I have a passion for the pursuit of people. There is a burning fire 
                                inside me that wants to make a big difference in many people’s lives. I have been in the HVAC 
                                trade for more than 7 years and have put many hours in at work. I am a husband and father and 
                                I love spending time with my family. 
                                </Typography> 
                                <br/>
                                <Typography gutterBottom variant="body1" color="textSecondary" component="p">
                                Some of our favorite activities are fishing, driving, and 
                                sitting on the beach. I also have a dog named Kenai; he is a well modeled citizen. My family matters 
                                the most and I love to make life better for them. I am looking forward to building up TheEssentiaList 
                                to be a providing source of income and knowledge for many families and individuals. We are all in life 
                                together and I believe when we work together the <em>impossible</em> becomes <em>possible</em>.
                                </Typography>
                                <br></br>
                            </CardContent>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <CardContent className={classes.card}>
                                <Typography gutterBottom variant="h5" component="h2">
                                    The Company
                                </Typography>
                                <Typography gutterBottom variant="body1" color="textSecondary" component="p">
                                    Welcome to  TheEssentiaList, the place where lives are changed through the trades.
                                </Typography><br></br>
                                <Typography gutterBottom variant="body1" color="textSecondary" component="p">
                                    The purpose of this company is to provide the essentials for the tradesman to bring 
                                    the highest quality work to our wonderful customers. A single question formed the foundation
                                    of this company: How can a tradesman escape his 
                                    9 to 5 job but continue to work in the field he is passionate about without the risk? 
                                    The idea of working part time on the side to build a sustaining customer base and bring in 
                                    a steady paycheck 
                                    to provide for the family has always been an interest of mine. Here at TheEssentiaList
                                    we are building just that: A system that allows anyone who has a passion to work with his
                                    hands and loves to serve people the ability to live a fulfilling life through it. <br></br>On their 
                                    time. On their efforts. But on our system.
                                </Typography>
                            </CardContent>
                        </Grid>
                        <Grid item xs={12}>
                            <CardContent className={classes.card}>
                                <Typography variant="body1"  component="p">
                                    Need work done?
                                    <br></br>    
                                    <Link color="textSecondary" href= '/contact'>
                                        Contact Us Here
                                    </Link>
                                </Typography>
                            </CardContent>
                        </Grid>
                    </Grid>
                
                    
                </CardActionArea>
            </Card>
        </Grid>
    ); 
}
