import React from 'react';
import Carousel from 'react-material-ui-carousel'
import { Link, Grid, Card, Typography, Button, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import carpentry from '../components/serviceimgs/carpentry.jpg';
//import custom from '../components/serviceimgs/custom.jpg';
import drywall from '../components/serviceimgs/drywall.jpg';
import flooring from '../components/serviceimgs/flooring.jpg';
import gutter from '../components/serviceimgs/guttercat.jpg';
import kitchen from '../components/serviceimgs/kitchen.jpg';
import metalwork from '../components/serviceimgs/metalwork.jpg';
import painting from '../components/serviceimgs/painting.jpg';
import picturehang from '../components/serviceimgs/picturehang.jpg';
import sink from '../components/serviceimgs/sink.jpg';
import plumbing from '../components/serviceimgs/plumbing.jpg';

import { MDBContainer, MDBFooter } from 'mdbreact';

const UseStyles = makeStyles({
    services: {
        padding: 10, 
        width: '90%', 
        maxWidth: 1000,
        margin: '50px auto',
        paddingBottom: 200
    },
    carousel: {
        width: '100%',
        opacity: 0.8,
    },
    grid: {
        padding: 15
    },
    card: {
        paddingTop: 50,
        maxHeight: 300, 
        maxWidth: 300,
    },
    button: {
        margin:'50px auto'
    },
    servicesTitle: {
        marginBottom: 50
    },
    serviceTitle: {
        textDecoration: 'underline',
        padding: 10
    },
    accord: {
        padding: 20,
    },
});

export default function Example(props) {
    const classes = UseStyles();

    var carpentry_items = [
        {
            name: "Before",
            description: "Image(s) coming soon!",
        },
        {
            name: "After",
            description: "Image(s) coming soon!",
        },
    ]
    var drywall_items = [
        {
            name: "Before",
            description: "Image(s) coming soon!",
        },
        {
            name: "After",
            description: "Image(s) coming soon!",
        },
    ]
    var flooring_items = [
        {
            name: "Before",
            description: "Image(s) coming soon!",
        },
        {
            name: "After",
            description: "Image(s) coming soon!",
        },
    ]
    var gutter_cleaning_items = [
        {
            name: "Before",
            description: "Image(s) coming soon!",
        },
        {
            name: "After",
            description: "Image(s) coming soon!",
        },
    ]
    var kitchen_remodeling_items = [
        {
            name: "Before",
            description: "Image(s) coming soon!",
        },
        {
            name: "After",
            description: "Image(s) coming soon!",
        },
    ]
    var metal_work_items = [
        {
            name: "Before",
            description: "Image(s) coming soon!",
        },
        {
            name: "After",
            description: "Image(s) coming soon!",
        },
    ]
    var painting_items = [
        {
            name: "Before",
            description: "Image(s) coming soon!",
        },
        {
            name: "After",
            description: "Image(s) coming soon!",
        },
    ]
    var plumbing_items = [
        {
            name: "Before",
            description: "Image(s) coming soon!",
        },
        {
            name: "After",
            description: "Image(s) coming soon!",
        },
    ]
    var picture_hanging_items = [
        {
            name: "Before",
            description: "Image(s) coming soon!",
        },
        {
            name: "After",
            description: "Image(s) coming soon!",
        },
    ]
    var sink_remodeling_items = [
        {
            name: "Before",
            description: "Image(s) coming soon!",
        },
        {
            name: "After",
            description: "Image(s) coming soon!",
        },
    ]

    /*var custom_items = [
        {
            name: "Job Card",
            description: "Text"
        },
        {
            name: "Job Card",
            description: "Text"
        },
        {
            name: "Job Card",
            description: "Text"
        },
    ]*/

    return (
        <div>
        <Grid className={classes.services}>
            <Grid className={classes.servicesTitle} align='center'>
                <h1 style={{ color: 'white' }}>Our Services</h1>
            </Grid>
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Accordion style={{backgroundImage: `url(${carpentry})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                            <Card className={classes.titleCard}>
                                <Typography className={classes.serviceTitle}>
                                    <h1>Carpentry</h1>
                                </Typography>
                            </Card>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid className={classes.carousel}>
                                    <Carousel>
                                    {
                                        carpentry_items.map( (item, i) => <Item key={i} item={item} /> )
                                    }
                                    </Carousel>
                                </Grid>
                            </AccordionDetails>
                        </Accordion><br/>

                        <Accordion style={{backgroundImage: `url(${drywall})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                            <Card className={classes.titleCard}>
                                <Typography className={classes.serviceTitle}>
                                    <h1>Drywall</h1>
                                </Typography>
                            </Card>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid className={classes.carousel}>
                                    <Carousel>
                                    {
                                        drywall_items.map( (item, i) => <Item key={i} item={item} /> )
                                    }
                                    </Carousel>
                                </Grid>
                            </AccordionDetails>
                        </Accordion><br/>

                        <Accordion style={{backgroundImage: `url(${flooring})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                            <Card className={classes.titleCard}>
                                <Typography className={classes.serviceTitle}>
                                    <h1>Flooring</h1>
                                </Typography>
                            </Card>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid className={classes.carousel}>
                                    <Carousel>
                                    {
                                        flooring_items.map( (item, i) => <Item key={i} item={item} /> )
                                    }
                                    </Carousel>
                                </Grid>
                            </AccordionDetails>
                        </Accordion><br/>

                        <Accordion style={{backgroundImage: `url(${gutter})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                            <Card className={classes.titleCard}>
                                <Typography className={classes.serviceTitle}>
                                    <h1>Gutter Cleaning</h1>
                                </Typography>
                            </Card>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid className={classes.carousel}>
                                    <Carousel>
                                    {
                                        gutter_cleaning_items.map( (item, i) => <Item key={i} item={item} /> )
                                    }
                                    </Carousel>
                                </Grid>
                            </AccordionDetails>
                        </Accordion><br/>

                        <Accordion style={{backgroundImage: `url(${kitchen})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                            <Card className={classes.titleCard}>
                                <Typography className={classes.serviceTitle}>
                                    <h1>Kitchen Remodeling</h1>
                                </Typography>
                            </Card>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid className={classes.carousel}>
                                    <Carousel>
                                    {
                                        kitchen_remodeling_items.map( (item, i) => <Item key={i} item={item} /> )
                                    }
                                    </Carousel>
                                </Grid>
                            </AccordionDetails>
                        </Accordion><br/>
                    </Grid>

                    <Grid item xs={12} sm={6}>             
                        <Accordion id="painting" style={{backgroundImage: `url(${painting})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                            <Card className={classes.titleCard}>
                                <Typography className={classes.serviceTitle}>
                                    <h1>Painting</h1>
                                </Typography>
                            </Card>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid className={classes.carousel}>
                                    <Carousel>
                                    {
                                        painting_items.map( (item, i) => <Item key={i} item={item} /> )
                                    }
                                    </Carousel>
                                </Grid>
                            </AccordionDetails>
                        </Accordion><br/>

                        <Accordion style={{backgroundImage: `url(${plumbing})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                            <Card className={classes.titleCard}>
                                <Typography className={classes.serviceTitle}>
                                    <h1>Plumbing</h1>
                                </Typography>
                            </Card>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid className={classes.carousel}>
                                    <Carousel>
                                    {
                                        plumbing_items.map( (item, i) => <Item key={i} item={item} /> )
                                    }
                                    </Carousel>
                                </Grid>
                            </AccordionDetails>
                        </Accordion><br/>

                        <Accordion style={{backgroundImage: `url(${picturehang})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                            <Card className={classes.titleCard}>
                                <Typography className={classes.serviceTitle}>
                                    <h1>Picture Hanging</h1>
                                </Typography>
                            </Card>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid className={classes.carousel}>
                                    <Carousel>
                                    {
                                        picture_hanging_items.map( (item, i) => <Item key={i} item={item} /> )
                                    }
                                    </Carousel>
                                </Grid>
                            </AccordionDetails>
                        </Accordion><br/>

                        <Accordion style={{backgroundImage: `url(${sink})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                            <Card className={classes.titleCard}>
                                <Typography className={classes.serviceTitle}>
                                    <h1>Sink Remodeling</h1>
                                </Typography>
                            </Card>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid className={classes.carousel}>
                                    <Carousel>
                                    {
                                        sink_remodeling_items.map( (item, i) => <Item key={i} item={item} /> )
                                    }
                                    </Carousel>
                                </Grid>
                            </AccordionDetails>
                        </Accordion><br/>

                        <Accordion style={{backgroundImage: `url(${metalwork})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                            <Card className={classes.titleCard}>
                                <Typography className={classes.serviceTitle}>
                                    <h1>Metal Work</h1>
                                </Typography>
                            </Card>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid className={classes.carousel}>
                                    <Carousel>
                                    {
                                        metal_work_items.map( (item, i) => <Item key={i} item={item} /> )
                                    }
                                    </Carousel>
                                </Grid>
                            </AccordionDetails>
                        </Accordion><br/>

                    </Grid>
                </Grid>
            </div><br/>

            <MDBFooter>
        <div className="footer-copyright text-center py-3">
        <MDBContainer fluid>
        Not what you're looking for? <a href="/contact">Submit a custom request! </a>
        </MDBContainer>
        </div>
        </MDBFooter>

        </Grid>
        
    </div>
    )
}

function Item(props) {
    const classes = UseStyles();

    return (
        <Grid className={classes.grid}>
            <Grid align='center'>
                <Card className={classes.card}>
                    <h2 align='center'>{props.item.name}</h2>
                    <p align='center'>{props.item.description}</p>
                    <Button className={classes.button}>
                        <Link href= '/contact'>
                            Submit Request
                        </Link>
                    </Button>
                </Card>
            </Grid>
        </Grid>
    )
}
