import './App.css';
import React, { Component } from 'react';
//import ReactDOM from 'react-dom';

//Components
import Navbar from './components/Navbar.js';
import Footer from './components/Footer.js';

//Router
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import home from "./pages/home";
import about from "./pages/about";
import contact from "./pages/contact";
import services from "./pages/services";
import login from "./pages/login";
import logout from "./pages/logout";
import signUp from "./pages/signup";
import error from "./pages/error";
import { AuthProvider } from './contexts/AuthContext';

class App extends Component {
  render(){
    return (
      
      <Router>
        <AuthProvider>
          {/*Navbar*/}
          <Navbar />

          {/*Routes*/}
          <Switch>
            <Route path="/" exact component={home}/>
            <Route path="/about" component={about}/>
            <Route path="/contact" component={contact}/>
            <Route path="/services" component={services}/> 
            <Route path="/login" component={login}/>
            <Route path="/logout" component={logout}/>
            <Route path="/signup" component={signUp}/>
            <Route component={error}/>
          </Switch>
          {/*Footer*/}
          <Footer />
        </AuthProvider>
      </Router>
      
    );
  }
}

export default App;
