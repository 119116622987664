import React, {Component} from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import PersistentDrawerLeft from '../components/leftDrawer.js';

const styles = theme => ({
    root: {
        marginTop: theme.spacing.unit *3,
        width: '100%'
    },
    flex: {
        flex: 1
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20
    },
    title: {
        flexGrow: 1,
    },
    logo: {
        height: 50,
        width: 150,
        marginRight: 20
    },
})

class Navbar extends Component {
    render() {
        return (
            <div>
                <AppBar position="static" elevation={0}>
                    <PersistentDrawerLeft>
                    </PersistentDrawerLeft>
                </AppBar>
                <Toolbar/>
            </div>
        )
    }
}

Navbar.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Navbar);
