import React, { useReducer, useState } from "react";
import { Button, Grid, Card, Link, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from '../contexts/AuthContext'
import Alert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    login: {
        padding: 50, 
        height: '100vh', 
        width: 425, 
        margin: '50px auto'
    },
    button: {
        margin:'10px 0'
    },
    card: {
        maxWidth: 700,
        margin: '0 auto',
        padding: 30,
    },
    centerScreen: {
        margin: '100px auto'
    }
}));

export default function Login() {
    const classes = useStyles();
    const { login } = useAuth(); // pass currentUser here to retrieve name, email, etc.

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const history = useHistory();


    const [formInput, setFormInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
          email: "",
          password: "",
        }
      );

      async function handleSubmit(e) {
        e.preventDefault();
    
        let data = { formInput };

        //Validation: Check if password are equal @22mins
        try {
            setError('')
            setLoading(true)
            await login(data.formInput.email, data.formInput.password)
            history.push('/')
        } catch {
            setError('Failed to sign in')
        }
        setLoading(false)
      };
    
      const handleInput = e => {
        const name = e.target.name;
        const newValue = e.target.value;
        setFormInput({ [name]: newValue });
      };


    return (
        <Grid className={classes.login}>
            <div className={classes.centerScreen}>
                <Card className={classes.card}>
                    <Grid align='center'>
                        <h1>Login</h1>
                        {error && <Alert severity="error">{ error }</Alert>}
                    </Grid>
                    <form onSubmit={handleSubmit}>
                        <TextField name="email" defaultValue={formInput.email} onChange={ handleInput }label='Email' placeholder='you@email.com' type='email' fullWidth required></TextField>
                        <TextField name="password" defaultValue={formInput.password} onChange={ handleInput } label='Password' placeholder='Enter password' type='password' fullWidth required></TextField>
                        <Button className={classes.button} type='submit' color='primary' variant='contained' fullWidth>Login</Button>
                    </form>
                    <Typography> 
                        Don't have an account?
                        <span> </span>    
                        <Link href='/signup'>
                            Sign up here
                        </Link>
                    </Typography>
                </Card>
            </div>
        </Grid>
    ); 
}