import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Collapse, CssBaseline, Grid, Card, AppBar, Tabs, Tab, Typography, TextField, Box, Divider, Button, FormControl } from '@material-ui/core';
import axios from 'axios';
import Alert from '@material-ui/lab/Alert';

var valueCh = true;


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '30ch',
        },
        flexgrow: 1,
    },
    button: {
        width: 550,
        margin: '10px 0',
        [theme.breakpoints.down('xs')]: {
            width: 300, 
            margin:'10px 0', 
        },
    },
    contact: {
        padding: 10,
        width: 900,
        margin: '0 auto',
        paddingBottom: 300,
        [theme.breakpoints.down('sm')]: {
            width: 700, 
            padding: 10,
            paddingBottom: 300,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100vw', 
            padding: 10,
            paddingBottom: 300,
        },
    },
    card: {
        maxWidth: 700,
        margin: '0 auto',
        padding: 13,
    },
    tabBar: {
        background: 'transparent',
        boxShadow: 'none',
    },
    form: {
        width: 550,
        [theme.breakpoints.down('xs')]: {
            width: '70vw', 
        },
    },
    formHalf: {
        [theme.breakpoints.down('xs')]: {
            width: '70vw', 
        },
    },
    centerScreen: {
        margin: '50px auto'
    }
}));



export default function Contact() {
    const classes = useStyles();
    const [checked, setChecked] = useState(false);
    const [flag, setFlag] = useState(false);
    const [value, setValue] = React.useState(0);
    const [inputs, setInputs] = useState({ email: '', name: '', subject: '', description: '', date: '' })
    const handleChange = (event, newValue) => {
        //valueCh is used to determine which tab is currently visible
        setValue(newValue)
        if (newValue === 1) {
            inputs.date = '';
            valueCh = false;

        } else valueCh = true;

    }

    //updates variables on changes
    const textChange = e => {
        const { name, value } = e.target;
        setInputs(prev => ({ ...prev, [name]: value }))
    }

    useEffect(() => {
        setChecked(true);
    }, [])

    //sends the email on button submit. mailserver.js must be running on backend. add proxy to index.js
    const handleSubmit = e => {
        e.preventDefault();
        var { email, name, subject, description, date } = inputs;
        if (valueCh == true) {
            description = description;
        } else {
            subject = "Customer Contact"
        }
        axios.post('https://theessentialist-mailserver.herokuapp.com/sendtome', {
            email,
            name,
            subject,
            date,
            text: description
        })

        setFlag(true)
        
        setTimeout(function(){
            
            window.location.reload(false)
        }, 1000);
        
        
    }




    return (
        <div className={classes.root}>
            <Grid className={classes.contact}>
                <Grid className={classes.centerScreen} align='center'>
                    <h1 style={{ color: 'white', paddingBottom: '50px' }}>Contact Us</h1>
                    {flag && <div><Alert severity="success">Sent!</Alert><br/></div>}
                    <Card className={classes.card} variant="outlined">
                        <AppBar className={classes.tabBar} position="static">
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"
                            >
                                <Tab label="Request a job" {...a11yProps(0)} />
                                <Tab label="Get in touch" {...a11yProps(1)} />
                            </Tabs>
                        </AppBar>
                        <Divider />
                        <TabPanel value={value} index={0} >
                            <Grid container spacing={.1} alignItems="center" justify="center">
                                <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit} >
                                    <TextField className={classes.form} required id="outlined-basic" label="Name" name="name" variant="standard" value={inputs.name} onChange={textChange} />
                                    <TextField className={classes.form} required id="outlined-basic" label="Email" name="email" variant="standard" value={inputs.email} onChange={textChange} />
                                    <TextField className={classes.formHalf} required id="outlined-basic" label="Service" name="subject" variant="standard" value={inputs.subject} onChange={textChange} />
                                    <TextField className={classes.formHalf} id="date" name="date" label="Deadline" type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={inputs.date}
                                        onChange={textChange}
                                    />
                                    <TextField
                                        className={classes.form}
                                        id="message-textfield"
                                        label="Job Description"
                                        name="description"
                                        multiline
                                        required
                                        rows={4}
                                        variant="outlined"
                                        value={inputs.description}
                                        onChange={textChange}
                                    />
                                    <Button className={classes.button} type='submit' color='primary' variant='contained' fullWidth >Send</Button>
                                </form>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={value} index={1} >
                            <Grid container spacing={.1} alignItems="center" justify="center">
                                <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
                                    <TextField className={classes.form} required id="outlined-basic" label="Name" name="name" variant="standard" value={inputs.name} onChange={textChange} />
                                    <TextField className={classes.form} required id="outlined-basic" label="Email" name="email" variant="standard" value={inputs.email} onChange={textChange} />
                                    <TextField
                                        className={classes.form}
                                        id="message-textfield"
                                        label="Message"
                                        name="description"
                                        multiline
                                        required
                                        rows={4}
                                        variant="outlined"
                                        value={inputs.description}
                                        onChange={textChange}
                                    />
                                    <Button className={classes.button} type='submit' color='primary' variant='contained' fullWidth >Send</Button>
                                </form>
                            </Grid>
                        </TabPanel>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )


}