import React, { useEffect, useState, useReducer } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
//import Search from '../components/Search.js';
import { Collapse, CssBaseline, Grid, Card, AppBar, Tabs, Tab, Typography, Box, Divider, Button, FormControl } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import homePageImage from '../components/homepage-image.jpg';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useAuth } from '../contexts/AuthContext' // add log out here

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        minHeight: '100vh',
        backgroundImage: `url(${homePageImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    title: {
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: '50vh',
        color: '#fff',
        fontSize: '2rem',
    },
    rootPaper: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
          margin: theme.spacing(1),
          width: theme.spacing(40),
          height: theme.spacing(16),
        },
    },
    grid: {
        spacing: 0,
        alignItems: 'center',
        justifyContent: 'center',
    },
    formControl: {
        minWidth: 100,
    },
    card: {
        maxWidth: 700, 
        margin: '0 auto', 
        padding: 13,
        [theme.breakpoints.down('xs')]: {
            width: '90%', 
        },
    },
    tabBar: {
        background: 'transparent', 
        boxShadow: 'none',
    },
    searchButton: {
        maxWidth: '30px', 
        maxHeight: '30px', 
        minWidth: '30px', 
        minHeight: '30px', 
        color: '#212121'
    },
    getStartedButton: {
        color: '#212121'
    },
    arrowForward: {
        color: '#212121',
        [theme.breakpoints.down('xs')]: {
            marginLeft: 200
        },
    },
    homepageHeading: {
        marginBottom: 30
    },
    searchBox: {
        [theme.breakpoints.down('xs')]: {
            width: '80%', 
        },
    }
}));

export default function Home() {
    const classes = useStyles();
    const [checked,setChecked] = useState(false);
    const [value, setValue] = React.useState(0);
    const { currentUser } = useAuth();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() =>{
        setChecked(true);
    },[])



// Need to add inputValue to pull option data, see https://material-ui.com/components/autocomplete/


    const [inputValue, setInputValue] = React.useState('');





    const [formInput, setFormInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
          service: "",
        }
      );

      const handleSubmit = e => {
        e.preventDefault();
    
        let data = `${inputValue}`;
    
        //alert(data)
      };
    
      const handleInput = e => {
        const name = e.target.name;
        const newValue = e.target.value;
        setFormInput({ [name]: newValue });
      };


    return (
        <div className={classes.root}>
            <CssBaseline />
            <div className={classes.title}>
                <Collapse in={checked} {...(checked ? { timeout:1800 } : {})} collapsedHeight={50}>
                <h1 class='h1'>
                    Welcome to <br/> TheEssentiaList
                </h1>
                </Collapse>
            </div>
            <Card className={classes.card} variant="outlined">
                <AppBar className={classes.tabBar} position="static">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        <Tab label="Hire a pro" {...a11yProps(0)} />
                        <Tab label="Find customers" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
                <Divider/>
                <TabPanel value={value} index={0}>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={.1} alignItems="center" justify="center">
                            <Grid item sm={12}>
                                    <h1 className={classes.homepageHeading}>Find local handyman professionals.</h1>
                            </Grid>
                            <Grid item sm={5} xs={12}>
                                <h4>Browse available services.</h4>
                            </Grid>
                            <Grid item sm={6} xs={6}>
                                <FormControl className={classes.formControl}>
                                    
                                    {/* Search Component */}
                                    <Autocomplete
                                        //id="service_box"
                                        className={classes.searchBox}
                                        inputValue={inputValue}
                                        onInputChange={(event, newInputValue) => {
                                        setInputValue(newInputValue);
                                        }}
                                        options={offeredService}
                                        getOptionLabel={(option) => option}
                                        style={{ width: 300 }}
                                        renderInput={params => (
                                        <TextField {...params} className={classes.searchBox} name="service" label="What's on your to-do list?" variant="outlined" />
                                        )}
                                    />


                                </FormControl>
                            </Grid>
                            <Grid item sm={1} xs={6}>
                                <Button href='/services' className={classes.searchButton} display="inline" type="submit" value="Submit">
                                    <ArrowForwardIcon className={classes.arrowForward}/>
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Grid container spacing={1} alignItems="center" justify="center">
                        <Grid item xs={12}>
                            <h1 className={classes.homepageHeading}>Find new customers.</h1>
                            <h4>Sign up to post jobs and start growing your business.</h4>
                        </Grid>
                        <Grid item xs={12}>
                        <Button className={classes.getStartedButton} variant="outlined" href='/signup'>
                            Get Started
                        </Button>
                        </Grid>
                    </Grid>
                </TabPanel>
            </Card>
        </div>
    );
}
 
const offeredService = ['Plumbing',
'Sink Remodeling',
'Kitchen Remodeling',
'Gutter Cleaning',
'Picture Hanging',
'Painting',
'Drywall',
'Flooring',
'Trim',
'Carpentry',
'Metal Work',
'Request Custom Service'
];

/*const offeredService = [
    { 
        title: 'Plumbing',
        tag: 'plumbing'
    },
    { 
        title: 'Sink Remodeling',
        tag: 'sink_remodeling' 
    },
    { 
        title: 'Kitchen Remodeling',
        tag: 'kitchen_remodeling'
    },
    { 
        title: 'Gutter Cleaning',
        tag: 'gutter_cleaning' 
    },
    { 
        title: 'Picture Hanging',
        tag: 'picture_hanging'
    },
    { 
        title: 'Painting',
        tag: 'painting'
    },
    { 
        title: 'Drywall',
        tag: 'drywall'
    },
    { 
        title: 'Flooring',
        tag: 'flooring'
    },
    { 
        title: 'Trim',
        tag: 'trim'
    },
    { 
        title: 'Carpentry',
        tag: 'carpentry'
    },
    { 
        title: 'Metal Work',
        tag: 'metal_work'
    },
    { 
        title: 'Request Custom Service',
        tag: 'request_custom_service'
    },
]; */