import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useAuth } from '../contexts/AuthContext'
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import avatar from '../components/login.png';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useHistory } from 'react-router';

const StyledBadge = withStyles((theme) => ({
    badge: {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: '$ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }))(Badge);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    typography: {
        padding: theme.spacing(2),
    },
    button: {
        marginTop: '20px',
    },
    img: {
        height: 35,
        width: 35
    },
}));

export default function UserDisplay() {
    const classes = useStyles();
    const { currentUser, logout } = useAuth();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [error, setError] = useState('')
    const history = useHistory()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    async function handleLogout(){
        setError('')

        try{
            await logout()
            history.push('/logout')
        } catch{
            setError('Failed to log out')
        }
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div className={classes.root}>
        <Button className={classes.button} aria-describedby={id} color="primary" onClick={handleClick}>
        {currentUser && 
            <StyledBadge
                overlap="circle"
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
                }}
                variant="dot"
            >
                <Avatar className={classes.img} src={AccountCircleIcon} />
            </StyledBadge>
        }
        {!currentUser && 
            <StyledBadge
                overlap="circle"
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
                }}
            >
                <Avatar className={classes.img} src={AccountCircleIcon} />
            </StyledBadge>
        }
        </Button>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            {currentUser && 
            <div>
                <Typography className={classes.typography}>Logged in with: {currentUser && currentUser.email}</Typography>
                <Button style={{float: 'right'}} onClick={handleLogout}>
                    Logout
                </Button>
            </div>
            }
            {!currentUser && <Typography className={classes.typography}>
                <Button href='/login'>
                    Login
                </Button>
                <span> | </span>
                <Button href='/signup'>
                    Sign Up
                </Button> 
            </Typography>}
        </Popover>
        </div>
    );
}
