import React from 'react';
import { Grid, Card, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    login: {
        padding: 50, 
        height: '100vh', 
        width: 425, 
        margin: '50px auto'
    },
    card: {
        maxWidth: 700,
        margin: '0 auto',
        padding: 30,
    },
    centerScreen: {
        margin: '200px auto'
    }
}));

export default function Logout() {
    const classes = useStyles();

    return (
        <Grid className={classes.login}>
            <Grid className={classes.centerScreen} align='center'>
                <Card className={classes.card}>
                    <h1>You're signed out.</h1>
                    <Typography> 
                        Want to sign back in?
                        <span> </span>    
                        <Link href='/login'>
                            Click here
                        </Link>
                    </Typography>
                </Card>
            </Grid>
        </Grid>
    ); 
}