import React from 'react';
 
const Error = () => {
    return (
       <div>
          <br/><h1>Error: Page does not exist!</h1>
       </div>
    );
}
 
export default Error;
