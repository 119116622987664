import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import Link from '@material-ui/core/Link';
import UserDisplay from '../components/userDisplay.js';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import BuildIcon from '@material-ui/icons/Build';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { useAuth } from '../contexts/AuthContext'

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        paddingBottom: '10px'
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginTop: '20px',
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    img: {
        height: 50,
        width: 220,
        marginRight: 15,
        marginTop: 10,
    },
     navBaricon: {
         width: 50,
         paddingRight: 10
    },

    navbarTitle: {
        color: '#fff',
    },
    logo: {
        flex: 1
    },
}));

export default function PersistentDrawerLeft() {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const { currentUser } = useAuth();

    const handleDrawerOpen = () => {
      setOpen(true);
    };
    const handleDrawerClose = () => {
      setOpen(false);
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                color="primary"
                className={clsx(classes.appBar, {
                  [classes.appBarShift]: open,
                })}
            >
              <Toolbar>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    className={clsx(classes.menuButton, open && classes.hide)}
                  >
                  <MenuIcon />
                  </IconButton>
                  <Typography className={classes.logo} color="primary" variant="h6" noWrap>
                      <Link href='/'>
                          <div className={classes.navbarTitle}>
                            <img src="logo.png" alt="Logo" className={classes.img}/> 
                          </div>
                      </Link>
                  </Typography>
                  <UserDisplay>
                  </UserDisplay>
              </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                  paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose}>
                      {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </div>
                <Divider />
                <List>
                    <ListItem>
                        <Link href="/about" variant="h6" color="inherit" className={classes.title}>
                            <MenuBookIcon fontSize="large" className={classes.navBaricon}/>
                            {'About'}
                        </Link>
                    </ListItem>
                    <ListItem>
                        <Link href="/contact" variant="h6" color="inherit" className={classes.title}>
                            <MailOutlineIcon fontSize="large" className={classes.navBaricon}/>
                            { 'Contact'}
                        </Link>
                    </ListItem>
                    <ListItem>
                        <Link href="/services" variant="h6" color="inherit" className={classes.title}>
                            <BuildIcon fontSize="large" className={classes.navBaricon}/>
                           {'Services'}
                        </Link>
                    </ListItem>
                </List>
                <Divider />
                {!currentUser &&
                <List>
                    <ListItem>
                    <Link href="/signup" variant="h6" color="inherit" className={classes.title}>
                        <PersonAddIcon fontSize="large" className={classes.navBaricon}/> 
                        {'Sign Up'}
                    </Link>
                    </ListItem>
                    <ListItem>
                      <Link href="/login" variant="h6" color="inherit" className={classes.title}>
                        <LockOpenIcon fontSize="large" className={classes.navBaricon}/> 
                        {'Login'}
                      </Link>
                    </ListItem>
                    {/*<ListItem>
                    <Link href="/logout" variant="h6" color="inherit" className={classes.title}>
                        <ExitToAppIcon fontSize="large" className={classes.navBaricon}/> 
                        {'Logout'}
                    </Link>
                    </ListItem>*/}
                </List>
                }
            </Drawer>
        </div>
    );
}
