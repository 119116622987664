import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import spinner from './components/preloader.png';
import $ from 'jquery'; 
import reportWebVitals from './reportWebVitals';
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';

const theme = createMuiTheme({
  palette: {
     primary: {
        main: "#1C2331"
               },
     secondary: {
        main: "#3F51B5"
                }
           },
});

/*routes/index.js
export default function mailLoad(){
router.get('/email/template', (req, res, next) => {
  MailConfig.ViewOption(gmailTransport,hbs);
  let HelperOptions = {
    from: '"Tariqul islam" <theessentialist.biz@gmail.com>',
           to: 'jhildz737@gmail.com',
           subject: 'Hellow world!',
           template: 'test',
           context: {
             name:"justin",
             email: "theessentialist.biz@gmail.com",
      address: "52, Kadamtola Shubag dhaka"
    }
  };
  gmailTransport.sendMail(HelperOptions, (error,info) => {
    if(error) {
      console.log(error);
      res.json(error);
    }
    console.log("email is send");
    console.log(info);
    res.json(info)
  });
});
};*/

// $(window).on('load', function() {
//   $('#overlay').fadeOut();
// });

ReactDOM.render(
    <React.StrictMode>
      {/* <div id="overlay" class="preloader">
        <img src={spinner} alt="spinner"/>
      </div> */}
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
